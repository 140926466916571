import React from 'react'
import styled from 'styled-components'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import ParseContent from 'components/shared/ParseContent'

// CSS
import { FormCSS } from 'styles/FormStyle'

// Interface
import { FormProps } from 'components/flex/Form/FormShell'

const Section = styled.div`
  ${FormCSS}

  & .form-duuf-error {
    right: 15px;
  }

  & .form-duuf-input-email::placeholder {
    font-style: italic !important;
    opacity: 0.4;
  }

  & a:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`

const StyledFormDuuf = styled(FormDuuf)``

const FormMore: React.FC<FormProps> = ({ fields }) => (
  <Section className="pb-5">
    <div className="container pb-5">
      <div className="row align-items-center">
        <div className="col-4 col-sm-5">
          <ParseContent content={fields.description} />
        </div>
        <div className="col-8 col-sm-7">
          <StyledFormDuuf id={2} className="mt-5" generate={false}>
            <div className="row">
              <div className="col-lg-7">
                <FormDuufGroup showIds={[1]} />
              </div>
              <div className="col-lg-5 d-flex justify-content-start">
                <FormDuufSubmit />
              </div>
            </div>
          </StyledFormDuuf>
        </div>
      </div>
    </div>
  </Section>
)

export default FormMore
